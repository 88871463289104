td.td {
  border: 1px solid black;
  padding: .5rem;
}
tbody.tbody {
  border: 2px solid black;
  overflow-wrap: anywhere;
}
td.firstColumn {
  width: 50%;
}