.datePicker {
  width: 100%;
  height: 3.25rem;
  border-radius: 4px;
  background-color: var(--color-secondary-input-lightest);
  border: 1px solid var(--color-secondary-input-lighter);
  box-shadow: 0 2px 6px 0 var(--color-secondary-inactive-lighter);
  height: var(--shared-input-height);
  padding-top: 1.5rem;
  padding-right: 0;
  padding-bottom: .75rem;
  padding-left: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  outline: none;
  box-shadow: 0 2px 6px 0 var(--color-secondary-inactive-lighter);
  transition: var(--motion-transition-easeInOut-short);
}