.scale {
  height: 4px;
  border-radius: 99px;
}

.green.scale {
  background-color: #53A8A0;
}

.yellow.scale {
  background-color: #FFAA2B;
}

.red.scale {
  background-color: #C12950;
}