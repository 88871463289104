.footer {
  color: var(--color-primary-body-base);
  font-size: var(--font-size-xSmall);
  max-width: 1280px;
  margin: 0 auto;
  margin-top: var(--space-xy-xLarge-rem);
  padding: var(--space-xy-small-rem) var(--space-xy-small-rem);
  @media (min-width: 768px) {
    padding: var(--space-xy-small-rem) var(--space-xy-large-rem);
  }
}

.footer-links {
  border-top: var(--border-width-xs) solid var(--color-secondary-inactive-lighter);
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--space-xy-xLarge-rem);
}

.footer-links > a {
  color: var(--color-primary-body-base);
  text-decoration: none;
  padding:  var(--space-xy-xxxSmall-rem) var(--space-xy-small-rem);
  padding-left: 0px;
  margin: var(--space-xy-xxSmall-rem) 0;
  margin-left: 0px;
}

.footer-links > a:before {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #e2e8f0;
  content: ""; 
  margin-right: 15px;
}
.footer-links > a:first-of-type:before, .footer-links > a:last-of-type:before{
  border: none; 
  margin-right: 0px;
}

.footer-links > a:hover {
  text-decoration: underline;
}

.footer-links > a:nth-last-of-type(2) {
  border-right: var(--border-width-xs) solid var(--color-secondary-inactive-lighter);
}
.footer-links > a:last-of-type {
  border-right: none;
  display: block;
  padding-left: 0;
  border-left: none;
  width: 100vw;
  margin-top: var(--space-xy-small-rem);
  padding-bottom: var(--space-xy-small-rem);
}

.footer-content > img {
  padding: var(--space-xy-medium-rem) 0 var(--space-xy-small-rem);
}

.footer-content > p {
  margin-bottom: 0;
  margin-top: 0;
  line-height: var(--font-lineHeight-xSmall);
}