.range-slider__thumb[data-lower] {
  display: none;
}

div.range-slider > div.range-slider__range {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 0 !important;
  background: #1780A3
}

div.range-slider > div.range-slider__thumb {
  background: #1780A3
}