div.radioButton > label {
  height: fit-content;
  padding: .75rem;
}

div.radioButton > label:hover {
  background-color: var(--color-secondary-input-lightest);
  border-color: var(--color-secondary-input-base);
}
div.radioButton.radioInline:first-of-type {
  margin-left: 0;
}
div.radioButton.radioInline {
  display: inline-block;
  margin-left: 5%;
}
div.radioButton.radio3 {
  width: 30%;
}

div.documentButton.radioButton > label {
  padding: 1.5rem;
  font-weight: bold;
}
fieldset:has(.radioFlex) {
  display: flex;
  gap: 1rem;
}