/* Tailwind */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* External Packages */
@import "react-datepicker/dist/react-datepicker.css";

/* Components */
@import "styles/components/Alert.css";
@import "styles/components/AffordabilityScale.css";
@import "styles/components/Card.css";
@import "styles/components/DatePicker.css";
@import "styles/components/Footer.css";
@import "styles/components/Form.css";
@import "styles/components/Progress.css";
@import "styles/components/RadioButtons.css";
@import "styles/components/Range.css";
@import "styles/components/SupportNavigation.css";
@import "styles/components/Table.css";
@import "styles/components/CreditScoreChart.css";

body {
  overflow-x: hidden;
}

div.dropdown {
  overflow: hidden;
}

input.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}
.linebreak {
  height: 2px;
  width: 100%;
  background: #D8D8D8;
}
.linebreak-thin {
  height: 1px;
  width: 100%;
  background: #D8D8D8;
}
.header-linebreak-thin {
  height: 1px;
  width: 100%;
  background: #F3F3F3;
}

.bold {
  font-weight: bold;
}

.tertiary {
  border: none!important;
  color: #1780a3
}
.tertiary:hover, .tertiary:focus {
  color: #106a88
}

.quaternary {
  border: none!important;
}

#finicityConnectIframe {
  width: 100%;
  height: 100%;
}
.icon-nav_exit_button {
  display: hidden;
}
.loader-xs > div {
  font-size: 2rem;
  border-width: 4px;
}

.loader-small > div {
  font-size: 3rem;
  border-width: 6px;
}

.loader-medium > div {
  font-size: 6rem;
  border-width: 12px;
}

div[role=tooltip][data-tooltip]::before{
  box-sizing: content-box;
  top: 140%;
}

div.danger > div[role=tooltip] {
  cursor: pointer;
}

div.danger > div[role=tooltip] > i {
  color: #dc2626;
}

a.underline {
  text-decoration: underline;
}

a.underline-hover:hover {
  text-decoration: underline;
}

.modal-content {
  max-width: 1024px !important;
}

.co-browse-modal > .modal-content {
  height: 172px !important;
}

.info-modal > .modal-content {
  height: fit-content !important;
}

div.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D8D8D8;
}

.owningBanner {
  height: 56px;
  border-radius: 9999px;
  background-color: var(--color-data-2);
  line-height: 3.5rem;
  font-size: 20px;
}

.owningTopBarOne {
  z-index: 10;
  content: "";
  position: absolute;
  left: -7px;
  width: 101%;
  height: 48px;
  background: var(--color-data-5);
}

.owningTopBarTwo {
  content: "";
  position: absolute;
  top: calc(10% + 15px);
  left: -7px;
  width: 101%;
  height: 144px;
  background: var(--color-data-2);
  transform-origin: top;
  transform: rotate(181.5deg);
}

.owning-logo-container {
  z-index: 1 !important;
}

.owningTopBarOneMobile {
  content: "";
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 40px;
  background: var(--color-data-5);
}

.color-5 {
  background: var(--color-data-5);
}

.owningTopBarTwoMobile {
  content: "";
  z-index: 0;
  position: absolute;
  top: calc(3% + 17px);
  left: 7px;
  width: 76%;
  height: 67px;
  background: var(--color-data-2);
  transform-origin: top;
  transform: skew(12deg);
}

.OwningHeaderTypeTwoTopBar {
  content: "";
  z-index: 0;
  position: absolute;
  width: 76%;
  height: 50px;
  background: var(--color-data-2);
  transform-origin: top;
  transform: skew(12deg);
} 
input:disabled {
  color: var(--color-secondary-inactive-base) !important;
}
input:disabled +label {
  color: var(--color-secondary-inactive-medium) !important;
}

.loanCard:hover, .locanCard:focus {
  border-color: var(--color-secondary-input-medium);
  background-color: var(--color-secondary-input-lightest);
}

.loanCard.cardSelected, .loanCard.cardSelected:hover, .locanCard.cardSelected:focus {
  border-color: var(--color-secondary-input-base);
  background-color: var(--color-secondary-input-lightest);
}

.loanTag {
  font-weight: bold;
  color: var(--color-secondary-input-base);
  background-color: #E7F2F5;
  white-space: nowrap;
}
.loanTag.tagSelected {
  color: white;
  background-color: var(--color-secondary-input-base);
}

.ieOrderStatusTag {
  font-weight: bold;
  width: max-content;
  color: var(--color-secondary-input-base);
  background-color: #E7F2F5;
}

.ieOrderStatusTag.Completed {
  color: rgb(5 150 105);
  background-color: rgb(236 253 245)
}

.ieOrderStatusTag.Submitted {
  color: #1780A3;
  background-color: #E7F2F5
}

.ieOrderStatusTag.Failed {
  color: rgb(200 25 25);
  background-color: rgb(254 242 242);
}

.react-datepicker-wrapper {
  width: 100%;
}
.infoBanner {
  background-color: var(--color-secondary-input-lightest);
}

.applicationButton:hover {
  background-color: var(--color-secondary-input-lightest);
  border-color: var(--color-secondary-input-base);
}

.modal.hac-rate.rates-visible > .modal-content, .modal.hac-rate.disclaimer-visible > .modal-content {
  height: auto;
}

.modal.hac-rate > .modal-content {
  height: fit-content;
}

.red-icon > div > i {
  color:rgb(200 25 25);
}

.grey-icon > div > i {
  color:rgb(128, 128, 128);
}

h4 {
  font-weight: normal;
}

.manualReview-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.manualReview-info > i {
  color: #1780A3;
}

.dropdown-text > div > label{
  font-size: 0.75rem;
}

.edit-button > span {
  font-size: 1.125rem;
}

.prefi-dropdown > div {
  padding-bottom: 0;
}

table.custom-table {
  border-collapse: collapse;
  width: 100%;
}

th.custom-table-th, td.custom-table-td {
  padding: 8px;
  text-align: left;
}

th.custom-table-th {
  font-weight: bold;
  border-bottom: 3px solid #eeeeee;
}

td.custom-table-td {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #cccccc;
}

.rc-font-icon.paystub {
 color: #25282A;
}

.paystub.loading > div {
  border-top-color: #25282A !important;
  border-left-color: #25282A !important;
  border-bottom-color: #25282A !important;
}

.paystub.error {
  color: var(--color-status-critical-base);
  font-family: "Proxima Nova";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  background-color: transparent;
}

/* modifies all link tags inside this container to be bolded and non-underlined */
.bold-link-container a {
  text-decoration: none;
  font-weight: 700;
}

.app-banner-gradient-background {
  background: linear-gradient(135deg, var(--color-secondary-action-hover) 0%, var(--color-secondary-action-lighter) 100%)
}
