.warning {
  background-color: #FFF6E9;
  border-color: #FFAA2B;
}
.warning > div > i {
  color: #FFAA2B;
}

.alert {
  padding: 1rem;
  border-radius: 12px;
}
.alert > div > i {
  font-weight: bold;
  font-size: 24px;
}
.error {
  background-color: rgb(254 242 242);
  border-color: rgb(200 25 25);
}
.alert.info {
  background-color: #E7F2F5;
  border-color: #1780A3;
}
.alert.info > div > i {
  color: #1780A3;
}

.alert.error > div > i {
color:rgb(200 25 25);
}

.success {
  background-color: rgb(236 253 245);
  border-color:  rgb(5 150 105);
}
.success > div > i {
  color:  rgb(5 150 105);
}
